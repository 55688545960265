import React from 'react';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';

//Import store
import { previewBookingSelector } from 'store/selectors';

// Import utils and helpers
import { useProfile, useTranslations } from 'components/utilities';
import { useBookingInvoice } from 'queries';
import { getInvoiceFields } from '../../helpers';

// Import components
import { AlertMessage, Card, EarlyReturnDialog } from 'components/elements';
import { PreviewFields } from '../../components';
import { ExtensionInvoice } from './components';

export const InvoiceCard = () => {
	const { t } = useTranslations();

	const { isAdmin } = useProfile();

	const booking = useSelector(previewBookingSelector);

	const { can_be_returned_earlier: canBeReturnedEarlier, subscription } =
		booking || {};

	const isEarlyReturnDialogVisible =
		!subscription && canBeReturnedEarlier && isAdmin;

	const { data, isFetching, isError } = useBookingInvoice(booking.uuid);

	if (isError) {
		return (
			<AlertMessage
				variant="error"
				title={t('common.error')}
				description={t('common.messages.error_message')}
			/>
		);
	}

	return (
		<Card
			title={t('bookings.preview_booking.invoice.title')}
			actions={isEarlyReturnDialogVisible && <EarlyReturnDialog />}
		>
			{isFetching ? (
				<CircularProgress size={28} />
			) : (
				<>
					<PreviewFields
						customData={{ ...data, booking }}
						fields={getInvoiceFields({ isAdmin })}
					/>
					<ExtensionInvoice />
				</>
			)}
		</Card>
	);
};
