import React from 'react';
import { Grid, Checkbox, FormControlLabel } from '@material-ui/core';

// Import utils
import { useTableExportContextProvider } from 'components/context/TableExportProvider/useTableExportContextProvider';
import { useTranslations } from 'components/utilities';

// Import helpers
import { sortColumns } from './helpers';

export const TableExportColumns = () => {
	const { columns, toggleColumnVisible } = useTableExportContextProvider();

	const { t } = useTranslations();

	const sortedColumns = sortColumns(columns);

	return (
		<Grid container spacing={1}>
			{sortedColumns.map(
				(column) =>
					column.isVisible && (
						<Grid item xs={12} md={6} lg={3} xl={2} key={column.accessor}>
							<FormControlLabel
								control={
									<Checkbox
										color="primary"
										checked={column.isVisible}
										onChange={() => toggleColumnVisible(column)}
									/>
								}
								label={t(column.Header)}
							/>
						</Grid>
					)
			)}
		</Grid>
	);
};
